import { strToDate } from './date'

export function cpfValidation(value) {
  const strCPF = String(value).replace(/[^\d]/g, '')
  if (/^(\d)\1{10}$/.test(strCPF) || strCPF === '00000000000') return false

  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) soma += parseInt(strCPF.charAt(i - 1)) * (11 - i)
  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(strCPF.charAt(9))) return false

  soma = 0
  for (let i = 1; i <= 10; i++)
    soma += parseInt(strCPF.charAt(i - 1)) * (12 - i)
  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(strCPF.charAt(10))) return false
  return true
}

export function dateFormatValidation(value) {
  const date = strToDate(value)

  return Boolean(date)
}

export function patternValidations(key) {
  const patterns = {
    name: '^[A-Za-z][A-Za-z ]+$',
  }

  return patterns[key]
}

export function regexValidation(value, regex) {
  const multi = Array.isArray(regex)
  const validation = multi ? regex.map((r) => new RegExp(r)) : new RegExp(regex)

  return multi ? validation.every((r) => r.test(value)) : validation.test(value)
}
