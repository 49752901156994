<template>
  <Dialog
    :visible="visible"
    header="Consignatárias"
    :closable="true"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block">
            <div class="inline-block col-12 md:col-12">
              <AutoComplete
                id="grupo"
                v-model="grupoSelecionado"
                :dropdown="true"
                scrollHeight="100px"
                :suggestions="filtroGrupos"
                field="nome"
                @item-select="selectGrupo($event)"
                @complete="searchGrupo($event)">
                <template #item="slotProps">
                  <div class="break-line">{{ slotProps.item.nome }}</div>
                </template>
              </AutoComplete>
            </div>
          </div>

          <div class="inline-block col-12 md:col-12">
            <DataTable
              :value="consignatarias"
              :loading="loadingConsignatarias"
              :selection.sync="consignatariasSelecionadas"
              responsiveLayout="scroll"
              dataKey="id">
              <Column
                selectionMode="multiple"
                :headerStyle="{ width: '3em' }"></Column>
              <Column field="razaoSocial" header="Razão Social"></Column>
              <Column field="nomeFantasia" header="Nome Fantasia"></Column>
            </DataTable>
          </div>
        </template>
      </Card>
    </BlockUI>

    <template #footer>
      <Button
        label="Fechar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button label="Salvar" icon="pi pi-check" @click="salvarUsuario()" />
    </template>
  </Dialog>
</template>
<script>
import GrupoService from '@/service/GrupoService'
import UsuarioService from '@/service/UsuarioService'

export default {
  props: {
    visible: { type: Boolean, required: true },
    usuario: { type: Object, required: false, default: () => {} },
  },

  data() {
    return {
      consignatarias: null,
      loadingConsignatarias: false,
      consignatariasSelecionadas: [],
      grupos: null,
      filtroGrupos: [],
      loadingGrupos: false,
      grupoSelecionado: null,
      saving: false,
    }
  },

  created() {
    this.service = new GrupoService(this.$http)
    this.usuarioService = new UsuarioService(this.$http)
  },

  mounted() {
    this.carregarGrupos()
  },

  methods: {
    fechar() {
      this.$emit('update:visible', false)
    },

    carregarGrupos() {
      this.loadingGrupos = true

      if (!this.grupos) {
        this.service
          .getListaGrupos()
          .then((res) => {
            this.grupos = res.data
            this.atualizarConsignatarias()
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err,
              life: 10000,
            })
          })
          .finally(() => {
            this.loadingGrupos = false
          })
      }
    },

    // exibirToastErro(err) {
    //   this.$toast.add({
    //     severity: 'error',
    //     summary: err.response.data.message,
    //     life: 10000,
    //   })
    // },

    atualizarConsignatarias() {
      if (this.usuario) {
        this.grupoSelecionado = this.usuario.grupo
        this.consignatariasSelecionadas = this.usuario.consignatarias

        if (this.usuario.grupo) {
          let grupo = this.grupos.filter((el) => {
            return el.id === this.usuario.grupo.id
          })
          if (grupo) {
            this.consignatarias = grupo[0].consignatarias
          }
        }
      }
    },

    onShow() {
      this.atualizarConsignatarias()
    },

    onHide() {
      this.consignatarias = []
      this.consignatariasSelecionadas = []
    },

    selectGrupo(grupo) {
      this.consignatariasSelecionadas = []
      this.consignatarias = grupo.value.consignatarias
    },

    searchGrupo(event) {
      this.filtroGrupos = this.grupos.filter((el) => {
        return el.nome.toLowerCase().includes(event.query.toLowerCase())
      })
    },

    salvarUsuario() {
      this.saving = true
      const user = JSON.parse(JSON.stringify(this.usuario))
      user.consignatarias = this.consignatariasSelecionadas
      user.grupo = this.grupoSelecionado
      const ativo = user.consignatarias.length > 0
      this.usuarioService
        .saveUsuarioConsignatariaPelaGestao(user, ativo)
        .then((res) => {
          if (this.usuario.id) {
            this.$emit('onUpdate', res.data)
            this.$toast.add({
              severity: 'success',
              summary: 'Usuário atualizado com sucesso.',
              life: 10000,
            })
          } else {
            this.usuarios.push(res.data)
            this.$toast.add({
              severity: 'success',
              summary: 'Usuário cadastrado com sucesso.',
              life: 10000,
            })
          }
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err,
            life: 10000,
          })
        })
        .finally(() => {
          this.saving = false
          this.fechar()
        })
    },
  },
}
</script>
<style></style>
