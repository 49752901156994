<template>
  <Dialog
    :visible="visible"
    header="Novo Usuário"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block mb-4">
            <div class="inline-block col-6 md:col-6">
              <label
                v-if="submitted && v$.nusuario.nome.required.$invalid"
                class="floating-error">
                Nome é obrigatório
              </label>
              <InputText id="nome" v-model="nusuario.nome" placeholder="Nome" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label
                v-if="submitted && v$.nusuario.email.required.$invalid"
                class="floating-error">
                E-mail é obrigatório
              </label>
              <label
                v-if="submitted && v$.nusuario.email.email.$invalid"
                class="floating-error">
                E-mail é inválido
              </label>
              <InputText
                id="mail"
                v-model="nusuario.email"
                placeholder="E-mail" />
            </div>
          </div>
          <div class="inline-block col-6 md:col-6">
            <label
              v-if="submitted && v$.nusuario.username.required.$invalid"
              class="floating-error">
              CPF é obrigatório
            </label>
            <label
              v-if="
                submitted &&
                v$.nusuario.username.cpf.$invalid &&
                !v$.nusuario.username.required.$invalid
              "
              class="floating-error">
              CPF é inválido
            </label>
            <InputMask
              id="cpf"
              v-model="nusuario.username"
              placeholder="CPF"
              :disabled="usuario.id"
              :unmask="true"
              mask="999.999.999-99" />
          </div>
          <div class="inline-block col-6 md:col-6">
            <label
              v-if="submitted && v$.nusuario.telefone.required.$invalid"
              class="floating-error">
              Telefone é obrigatório
            </label>
            <InputText
              id="telefone"
              v-model="nusuario.telefone"
              placeholder="Telefone" />
          </div>

          <!-- <div class="inline-block col-12 md:col-12">
            <label for="grupo">Grupo</label>
            <AutoComplete
              id="grupo"
              v-model="grupoSelecionado"
              :dropdown="true"
              scrollHeight="120px"
              :suggestions="filtroGrupos"
              field="nome"
              @item-select="selectGrupo($event)"
              @complete="searchGrupo($event)">
              <template #item="slotProps">
                <div class="break-line">{{ slotProps.item.nome }}</div>
              </template>
            </AutoComplete>
          </div> -->

          <!-- <div class="inline-block col-12 md:col-12">
            <div
              v-if="submitted && v$.usuario.consignatarias.required.$invalid"
              class="p-error">
              Selecione pelo menos uma consignatária.
            </div>
            <DataTable
              :value="consignatarias"
              :loading="loadingConsignatarias"
              :selection.sync="consignatariasSelecionadas"
              responsiveLayout="scroll"
              dataKey="id">
              <Column
                selectionMode="multiple"
                :headerStyle="{ width: '3em' }"></Column>
              <Column field="razaoSocial" header="Razão Social"></Column>
              <Column field="nomeFantasia" header="Nome Fantasia"></Column>
            </DataTable>
          </div> -->
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="salvarUsuario">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { cpfValidation } from '@/modules/validations.js'
// import GrupoService from '@/service/GrupoService'
import UsuarioService from '@/service/UsuarioService'
import Usuario from '@/domain/Usuario'

export default {
  props: {
    visible: { type: Boolean, required: true },
    usuario: { type: Object, required: false, default: new Usuario() },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  validations() {
    return {
      nusuario: {
        nome: { required },
        username: { required, cpf: cpfValidation },
        email: { required, email },
        telefone: { required },
        // consignatarias: { required, minLength: minLength(1) },
      },
    }
  },

  data() {
    return {
      nusuario: {},
      saving: false,
      confirmDialog: false,
      submitted: false,
      // grupoSelecionado: null,
      // loadingGrupos: false,
      // loadingConsignatarias: false,
      // grupos: null,
      // filtroGrupos: [],
      // consignatarias: [],
      // consignatariasSelecionadas: [],
    }
  },

  created() {
    // this.grupoService = new GrupoService(this.$http)
    this.usuarioService = new UsuarioService(this.$http)
  },

  mounted() {
    // this.carregarGrupos()
  },

  methods: {
    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      if (this.usuario != null) {
        this.nusuario = JSON.parse(JSON.stringify(this.usuario))
      }
      // this.grupoSelecionado = null
      // this.consignatarias = []
      // this.consignatariasSelecionadas = []
      // this.usuario = new Usuario()
    },

    onHide() {
      this.submitted = false
    },

    // selectGrupo(grupo) {
    //   this.consignatariasSelecionadas = []
    //   this.consignatarias = grupo.value.consignatarias
    // },

    // searchGrupo(event) {
    //   this.filtroGrupos = this.grupos.filter((el) => {
    //     return el.nome.toLowerCase().includes(event.query.toLowerCase())
    //   })
    // },

    exibirToastErro(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response.data.message,
        life: 10000,
      })
    },

    validate() {
      this.submitted = true
      this.v$.nusuario.$touch()
      return !this.v$.nusuario.$invalid
    },

    salvarUsuario() {
      if (this.nusuario.ativo == null) {
        this.nusuario.ativo = true
      }
      if (this.validate()) {
        this.saving = true
        this.usuarioService
          .saveUsuarioConsignatariaPelaGestao(this.nusuario, true)
          .then((res) => {
            this.$emit('onUpdate', res.data)
            if (this.nusuario.id) {
              this.$toast.add({
                severity: 'success',
                summary: 'Usuário atualizado com sucesso.',
                life: 10000,
              })
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Usuário cadastrado com sucesso.',
                life: 10000,
              })
            }
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.saving = false

            this.fechar()
          })
      }
    },

    // carregarGrupos() {
    //   this.loadingGrupos = true

    //   if (!this.grupos) {
    //     this.grupoService
    //       .getListaGrupos()
    //       .then((res) => {
    //         this.grupos = res.data
    //       })
    //       .catch((err) => {
    //         this.exibirToastErro(err)
    //       })
    //       .finally(() => {
    //         this.loadingGrupos = false
    //       })
    //   }
    // },
  },
}
</script>
<style>
.required:after {
  content: ' *';
  color: red;
}

.p-card {
  box-shadow: none;
}

.p-dialog .p-card .p-card-body {
  padding: 0rem !important;
}

.break-line {
  white-space: break-spaces;
}
.floating-error {
  position: absolute;
  color: red;
  font-size: smaller;
  top: -10px;
  left: 10px;
  transition: all 0.3s ease;
}
</style>
