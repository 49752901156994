<template>
  <Dialog
    header="Permissões"
    :visible="visible"
    :closable="true"
    :modal="true"
    :contentStyle="{ overflow: 'visible' }"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="loading">
      <Card>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <label for="perfilSeguranca">Perfil Segurança</label>
              <MultiSelect
                v-model="perfiLSegurancaSelecionado"
                :options="perfiLSegurancaOptions"
                optionLabel="nome"
                placeholder="Selecione o Perfil" />
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-6 md:col-6">
              <DataTable
                class="p-datatable-sm"
                show
                :value="gruposSelecionados"
                :paginator="true"
                :rows="5"
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                <Column field="nome" header="Grupo Seguranca"></Column>
              </DataTable>
            </div>
            <div class="col-6 md:col-6">
              <DataTable
                class="p-datatable-sm"
                :value="rolesSelecionadas"
                :paginator="true"
                :rows="5"
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                <Column field="nome" header="Roles"></Column>
              </DataTable>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button label="Salvar" autofocus :disabled="loading" @click="salvar()">
        <span v-if="!loading" class="pi pi-check"></span>
        <span v-if="loading" class="pi pi-spin pi-spinner"></span>
        <span class="ml-2">Salvar</span>
      </Button>
    </template>
  </Dialog>
</template>
<script>
import { watch } from 'vue-demi'

import PerfilSegurancaService from '@/service/PerfilSegurancaService'
import UsuarioService from '@/service/UsuarioService'
import Usuario from '@/domain/Usuario'

export default {
  props: {
    visible: { type: Boolean, required: true },
    usuario: { type: Object, required: false, default: () => {} },
  },

  data() {
    return {
      usuarioPerfil: new Usuario(),
      perfiLSegurancaSelecionado: [],
      perfiLSegurancaOptions: [],
      rolesSelecionadas: [],
      gruposSelecionados: [],
      loading: false,
    }
  },

  created() {
    this.usuarioService = new UsuarioService(this.$http)
    this.perfilSegurancaService = new PerfilSegurancaService(this.$http)
    this.carregarDados()
    watch(
      () => this.perfiLSegurancaSelecionado,
      () => {
        this.atualizarDados()
      },
      { deep: true },
    )
  },

  mounted() {},

  methods: {
    fechar() {
      this.$emit('update:visible', false)
    },

    carregarDados() {
      this.carregarPerfilSeguranca()
    },

    carregarPerfilSeguranca() {
      this.perfilSegurancaService
        .getAll()
        .then((res) => {
          this.perfiLSegurancaOptions = res
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
    },

    atualizarDados() {
      const arrayRoles = []
      this.perfiLSegurancaSelecionado.forEach((perfil) => {
        perfil.roles.forEach((role) => {
          if (!arrayRoles.find((item) => item.nome === role.nome)) {
            arrayRoles.push(role)
          }
        })
      })
      this.rolesSelecionadas = arrayRoles

      const arrayGrupos = []
      this.perfiLSegurancaSelecionado.forEach((perfil) => {
        perfil.grupos.forEach((grupo) => {
          if (!arrayGrupos.find((item) => item.nome === grupo.nome)) {
            arrayGrupos.push(grupo)
          }
        })
      })
      this.gruposSelecionados = arrayGrupos
    },

    onShow() {
      this.usuarioPerfil = JSON.parse(JSON.stringify(this.usuario))
      this.perfiLSegurancaSelecionado = this.usuarioPerfil.perfis
    },

    onHide() {},

    salvar() {
      this.loading = true
      this.usuarioPerfil.perfis = this.perfiLSegurancaSelecionado
      this.usuarioPerfil.grupos = this.perfiLSegurancaSelecionado.grupos
      this.usuarioPerfil.roles = this.perfiLSegurancaSelecionado.roles

      this.usuarioService
        .saveUsuario(this.usuarioPerfil)
        .then(() => {
          this.exibirToastSuccess('Permissões alteradas com sucesso!')
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
        .finally(() => {
          this.loading = false
          this.fechar()
        })
    },

    exibirToastSuccess(message) {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 10000,
      })
    },

    exibirToastErro(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response,
        life: 10000,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .p-card .p-card-body {
  padding: 0.5rem;
}

::v-deep .p-card {
  background: #ffffff;
  color: #212529;
  box-shadow: none;
  border-radius: 0px;
}
</style>
